// token
const TOKEN = 'statistics-gov-token'

// 机构号
const TENANTNO = 'statistics-gov-tenantNo'

// 用户信息
const UERINFO = 'statistics-gov-userInfo'

// 保存token
export function setToken(token) {
  return sessionStorage.setItem(TOKEN, token)
}

// 获取token
export function getToken() {
  return sessionStorage.getItem(TOKEN)
}

// 删除token
export function removeToken() {
  return sessionStorage.removeItem(TOKEN)
}

// 保存机构号
export function setTenantNo(tenantNo) {
  return sessionStorage.setItem(TENANTNO, tenantNo)
}

// 获取机构号
export function getTenantNo() {
  return sessionStorage.getItem(TENANTNO)
}

// 删除机构号
export function removeTenantNo() {
  return sessionStorage.removeItem(TENANTNO)
}

// 保存用户信息
export function setUserInfo(data) {
  return sessionStorage.setItem(UERINFO, JSON.stringify(data))
}

// 获取用户信息
export function getUserInfo() {
  return JSON.parse(sessionStorage.getItem(UERINFO))
}

// 删除用户信息
export function removeUserInfo() {
  return sessionStorage.removeItem(UERINFO)
}
