// 引入vue
import Vue from 'vue'
import store from '@/store'
// 获取到vuex中的按钮
function has() {
  let buttonArr = store.state.permission.button
  // 注册一个全局自定义指令 `v-auth`
  Vue.directive('auth', {
    // 当被绑定的元素插入到 DOM 中时
    inserted: (el, binding, vnode) => {
      const value = binding.value
      const auths = buttonArr
      if (value !== '') {
        if (auths.indexOf(value) == -1) {
          el.parentNode.removeChild(el)
        }
      }
    },
  })
}
export { has }
