<template>
  <div>
    <router-view />
    <webSocket ref="webSocket" uri="/msg/ws/notice" />
  </div>
</template>
<script>
import webSocket from '@/components/webSocket/index.vue'
export default {
  components: {
    webSocket,
  },
}
</script>
<style lang=""></style>
