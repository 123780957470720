import Layout from '@/layout'

// 机构二级
const institutionPage = {
  path: '/institution',
  component: Layout,
  redirect: '/institution/institutionPage',
  name: 'institution',
  children: [
    {
      path: 'institutionPage',
      component: () => import('@/views/institutionPage/index'),
      name: 'institutionPage',
      meta: {
        title: '机构二级',
      },
    },
    {
      path: 'dachuanPage',
      component: () => import('@/views/customPage/dachuanPages/index.vue'),
      name: 'dachuanPage',
      meta: {
        title: '达川区机构二级',
      },
    },
  ],
}

export default institutionPage
