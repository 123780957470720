import Layout from '@/layout'

// 政府二级
const govPage = {
  path: '/gov',
  component: Layout,
  redirect: '/gov/govPage',
  name: 'gov',
  children: [
    {
      path: 'govPage',
      component: () =>
        import('@/views/govPage/serviceOrderSearch/orderDetail.vue'),
      name: 'govPage',
      meta: {
        title: '政采项目总数据',
      },
    },
  ],
}

export default govPage
