<template>
  <div id="app" @click="clickApp">
    <audio
      ref="audioText"
      src="@/assets/audioOrVideo/aac.wav"
      style="display: none"
    ></audio>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isPlay: true,
    }
  },
  mounted() {
    this.calculated()
  },
  methods: {
    calculated() {
      // 1、页面一加载就要知道页面宽度计算
      var setFont = function () {
        // 因为要定义变量可能和别的变量相互冲突，污染，所有用自调用函数
        var html = document.documentElement // 获取html
        // 获取宽度
        var width = html.clientWidth
        // 判断
        if (width < 1024) width = 1024
        if (width > 1920) width = 1920
        // 设置html的基准值
        var fontSize = width / 80 + 'px'
        // 设置给html
        html.style.fontSize = fontSize
      }
      setFont()
      // 2、页面改变的时候也需要设置
      // 尺寸改变事件
      window.onresize = function () {
        setFont()
      }
    },
    clickApp() {
      if (this.isPlay) {
        let music1 = new Audio()
        music1 = require('@/assets/audioOrVideo/aac.wav')
        this.$refs.audioText.src = music1
        setTimeout(() => {
          this.$refs.audioText.play()
        })
        setTimeout(() => {
          this.isPlay = false
          this.$refs.audioText.pause()
        }, 1000)
      }
    },
  },
}
</script>

<style>
#app,
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-size: 0.5rem;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0 / cover;
}
* {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #8eb8e6;
  border-radius: 32px;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 32px;
}
</style>
