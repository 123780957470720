import { constantRoutes, asyncRoutes } from '@/router'
import _ from 'lodash'

// 按钮控制
import { has } from '@/utils/but-jurisdiction'

// 设置按钮权限
function buttonPermissions(commit, datas) {
  let arr = []
  datas.forEach((item) => {
    arr.push(item.code)
  })
  // 保存按钮权限
  commit('SET_BUTTON', arr)
  // 触发
  has()
}

const state = {
  button: [],
}

const mutations = {
  SET_BUTTON: (state, data) => {
    state.button = data
  },
}

const actions = {
  // 获取到路由
  getRouter({ commit }, data) {
    return new Promise((resolve) => {
      // 创建路由
      let list = data.resources.map((item) => {
        return item.code
      })
      // push404
      constantRoutes.push({ path: '*', redirect: '/404', hidden: true })
      let obj = {
        list,
        routes: [..._.cloneDeep(asyncRoutes), ..._.cloneDeep(constantRoutes)],
      }
      // 按钮权限
      buttonPermissions(commit, data.permissions)
      resolve(obj)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
