import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { getToken, getTenantNo } from '@/utils/auth'
import store from '@/store'
// 创建axios实例
import router from '@/router'
const base = process.env.VUE_APP_BASE_URL

const service = axios.create({
  baseURL: base,
  timeout: 60000, // 请求超时
})
// 退出提示次数
let signOutMesssage = 0
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    let needTenantNos = true
    let needTenantNo = config.needTenantNo
    if (typeof needTenantNo == 'boolean') {
      needTenantNos = needTenantNo
    }
    let token = getToken()
    let tenantNo = getTenantNo()
    if (needTenantNos && tenantNo) {
      config.headers['Authorization'] = token
      config.headers['YL-TENANT'] = tenantNo
    } else if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    let res = response
    console.log('response', res)
    if (res.data.code !== 0 && res.data.code !== 60001) {
      Message({
        message: res.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(new Error(res.data.message || 'Error'))
    }
    return res.data
  },
  (error) => {
    try {
      if (
        error.response.data.code === 87650002 ||
        error.response.data.code === 87650001 ||
        error.response.data.code === 104
      ) {
        // 只控制账后异常重新登陆防止多个弹框出现问题
        signOutMesssage++
        if (signOutMesssage <= 1) {
          MessageBox.confirm('该账号异常，请重新登录', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
            type: 'warning',
          }).then(() => {
            // 清除缓存
            signOutMesssage = 0
            sessionStorage.clear()
            store.dispatch('user/resetRole')
            router.push('/login')
          })
        }
      } else {
        Message({
          message: error.response.data.message,
          type: 'error',
          duration: 5 * 1000,
        })
        return Promise.reject(error)
      }
    } catch (error) {
      Message({
        message: '请求网络错误',
        type: 'error',
        duration: 5 * 1000,
      })
    }
  }
)

service.webSocketUrl = base

export default service
