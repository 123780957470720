import Layout from '@/layout'

// 其他页面
const otherPage = {
  path: '/other',
  component: Layout,
  redirect: '/warningCenter',
  name: 'other',
  children: [
    {
      path: 'warningCenter',
      component: () => import('@/components/warningCenter/index.vue'),
      name: 'warningCenter',
      meta: {
        title: '预警中心',
      },
    },
    {
      path: '/elderlyRecords',
      component: () => import('@/views/elderlyRecords/index.vue'),
      name: 'elderlyRecords',
      meta: {
        title: '长者档案数据',
      },
    },
    {
      path: '/elderlyMessage',
      component: () => import('@/views/elderlyMessage/index.vue'),
      name: 'elderlyMessage',
      meta: {
        title: '长者政采信息',
      },
    },
    {
      path: '/governmentProcurement',
      component: () => import('@/views/GovernmentProcurementPage/index.vue'),
      name: 'governmentProcurement',
      meta: {
        title: '政采门店数据',
      },
    },
  ],
}

export default otherPage
