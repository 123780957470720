// 登录-退出登录-获取用户信息
import { login, getInfo, logout, getAreaInfo } from '@/api/user'

import {
  getToken,
  setToken,
  removeToken,
  setTenantNo,
  removeTenantNo,
  setUserInfo,
  removeUserInfo,
} from '@/utils/auth.js'

const getDefaultState = () => {
  return {
    token: getToken(),
    roles: null,
  }
}

const state = getDefaultState()

const mutations = {
  // 注销登录时候合并对象
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  // 保存token
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  // 保存一份身份在vuex中
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  // 从vuex中将身份删除
  DELETE_ROLES: (state) => {
    state.roles = null
  },
}

const actions = {
  // 用户登录
  login({ commit }, userInfo) {
    // 解构用户名和密码
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username, password: password })
        .then((response) => {
          let _data = response.data
          //判断是否接口成功
          if (response.code == 0) {
            //获取到的token
            let token = _data.token_type + _data.access_token
            //保存token到vuex中
            commit('SET_TOKEN', token)
            //保存token到本地
            setToken(token)
            //保存租户号到本地
            setTenantNo(_data.account.tenantNo)
            //通过
            resolve(response)
          } else {
            //通过
            resolve(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 获取用户信息
  getInfo({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      // 发送请求
      getInfo()
        .then(async (response) => {
          // 解构获取的数据
          const data = response.data
          // 判断是否有数据
          if (!data) {
            return reject('验证失败，请重新登录')
          }
          // 获取到区域的详情加入到info中
          let areaInfoData = await dispatch('getAreaInfo', data)
          data['province'] = areaInfoData.names.split('|')[0]
          data['city'] = areaInfoData.names.split('|')[1]
          data['area'] = areaInfoData.names.split('|')[2]
          data['provinceCode'] = areaInfoData.codes.split('|')[0]
          data['cityCode'] = areaInfoData.codes.split('|')[1]
          data['areaCode'] = areaInfoData.codes.split('|')[2]
          // 保存用户信息到本地
          setUserInfo(data)
          // 保存一份身份在vuex中
          commit('SET_ROLES', data.roles)
          // 通过
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 用户退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          // 必须先删除令牌
          removeToken()
          // 注销登录时候合并对象
          commit('RESET_STATE')
          // 清除租户号
          removeTenantNo()
          // 清除用户信息
          removeUserInfo()
          // 清除login用到的activeCode高亮状态
          sessionStorage.removeItem('activeCode')
          // 清空用户密码，和登录用户名
          sessionStorage.removeItem('userName')
          sessionStorage.removeItem('password')
          // 清空保存的租户号列表
          sessionStorage.removeItem('hasAreaList')
          // 通过
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 无token 清空 退出
  toLoginPage({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      // 必须先删除令牌
      removeToken()
      // 注销登录时候合并对象
      commit('RESET_STATE')
      // 清除租户号
      removeTenantNo()
      // 清除用户信息
      removeUserInfo()
      // 清除login用到的activeCode高亮状态
      sessionStorage.removeItem('activeCode')
      resolve()
    })
  },

  // 删除令牌
  resetToken({ commit }) {
    return new Promise((resolve) => {
      // 必须先删除令牌
      removeToken()
      // 注销登录时候合并对象
      commit('RESET_STATE')
      resolve()
    })
  },

  // 清除身份
  resetRole({ commit }) {
    commit('DELETE_ROLES')
  },

  // 获取区域详情
  getAreaInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAreaInfo(data.areaCode).then((res) => {
        if (res.code == 0) {
          resolve(res.data)
        }
      })
    })
  },
}

export default {
  // 这个属性可以在页面中触发异步函数时候,可写入url,而不需要写函数名
  namespaced: true,
  state,
  mutations,
  actions,
}
