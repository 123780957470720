<template>
  <div>
    <!-- 报警声音控件 -->
    <audio class="aud" controls preload="auto" id="audio">
      <source src="./static/yj.wav" />
    </audio>
    <!-- 报警弹窗 -->
    <alarmBox
      :show="show"
      @close="closeFun"
      @tohandle="tohandle"
      :alarmData="alarmData"
    ></alarmBox>
  </div>
</template>
<script>
import request from '@/utils/request'
import alarmBox from '@/components/webSocket/alarmBox.vue'
import { getToken, getTenantNo } from '@/utils/auth.js'

export default {
  name: 'YiLunWebSocket',
  props: {
    uri: {
      type: String,
      default: '/msg/ws/notice', //websocket 链接地址
    },
  },
  components: {
    alarmBox,
  },
  data() {
    return {
      webSocket: null, // webSocket实例
      lockReconnect: false, // 重连锁，避免多次重连
      maxReconnect: 6, // 最大重连次数， -1 标识无限重连
      reconnectTime: 0, // 重连尝试次数
      heartbeat: {
        interval: 30 * 1000, // 心跳间隔时间
        timeout: 10 * 1000, // 响应超时时间
        pingTimeoutObj: null, // 延时发送心跳的定时器
        pongTimeoutObj: null, // 接收心跳响应的定时器
        pingMessage: JSON.stringify({ type: 'ping' }), // 心跳请求信息
      },
      play: false, // 报警声音的控制
      show: false, // 对话框显示隐藏
      alarmData: null, // 报警消息数据
    }
  },
  computed: {
    token() {
      //认证token
      return getToken().substring('bearer'.length)
    },
    tenant() {
      //用户所在租户号
      return getTenantNo() ? getTenantNo() : 'G000001'
    },
  },
  created() {
    // 加载websocket链接
    this.initWebSocket()
  },
  destroyed: function () {
    console.log('退出了登录，清除websocket')
    //页面销毁后需要关闭websocket，并清除定时器
    if (this.webSocket) {
      this.webSocket.close()
    }
    this.clearTimeoutObj(this.heartbeat)
  },
  methods: {
    /**
     * 初始化 weoSocket
     */
    initWebSocket() {
      if (!this.token) {
        return
      }
      // ws地址 开发时请自行解决host自定义问题
      const host = request.webSocketUrl.substring(
        request.webSocketUrl.lastIndexOf('//')
      )
      const wsUri = `wss:${host}${this.uri}?access_token=${this.token}&tenant_no=${this.tenant}`
      // const wsUri = `ws:${host}${this.uri}?access_token=${this.token}&tenant_no=${this.tenant}`
      // 建立连接
      this.webSocket = new WebSocket(wsUri)
      // 连接成功
      this.webSocket.onopen = this.onOpen
      // 连接错误
      this.webSocket.onerror = this.onError
      // 接收信息
      this.webSocket.onmessage = this.onMessage
      // 连接关闭
      this.webSocket.onclose = this.onClose
    },
    /**
     * 重新连接
     */
    reconnect() {
      if (!this.token) {
        return
      }
      if (
        this.lockReconnect ||
        (this.maxReconnect !== -1 && this.reconnectTime > this.maxReconnect)
      ) {
        return
      }
      console.log(this.lockReconnect, this.maxReconnect, this.reconnectTime)
      this.lockReconnect = true
      setTimeout(() => {
        this.reconnectTime++
        console.log('重新链接websokect')
        // 建立新连接
        this.initWebSocket()
        this.lockReconnect = false
      }, 5000)
    },
    /**
     * 清空定时器
     */
    clearTimeoutObj: function (heartbeat) {
      heartbeat.pingTimeoutObj && clearTimeout(heartbeat.pingTimeoutObj)
      heartbeat.pongTimeoutObj && clearTimeout(heartbeat.pongTimeoutObj)
    },
    /**
     * 开启心跳检测
     */
    startHeartbeat() {
      const webSocket = this.webSocket
      const heartbeat = this.heartbeat
      // 清空定时器
      this.clearTimeoutObj(heartbeat)
      // 延时发送下一次心跳
      heartbeat.pingTimeoutObj = setTimeout(() => {
        // 如果连接正常
        if (webSocket.readyState === 1) {
          //这里发送一个心跳，后端收到后，返回一个心跳消息，
          webSocket.send(heartbeat.pingMessage)
          // 心跳发送后，如果服务器超时未响应则断开，如果响应了会被重置心跳定时器
          heartbeat.pongTimeoutObj = setTimeout(() => {
            webSocket.close()
          }, heartbeat.timeout)
        } else {
          // 否则重连
          this.reconnect()
        }
      }, heartbeat.interval)
    },
    /**
     * 连接成功事件
     */
    onOpen() {
      console.log('WebSocket connection success')
      //开启心跳
      this.startHeartbeat()
      this.reconnectTime = 0
    },
    /**
     * 连接失败事件
     * @param e
     */
    onError(e) {
      //错误
      console.log(
        `WebSocket connection error：${e.code} ${e.reason} ${e.wasClean}`
      )
      // 重连
      if (this.$route.path == '/' || this.$route.path == '/login') {
      } else {
        this.reconnect()
      }
    },
    /**
     * 连接关闭事件
     * @param e
     */
    onClose(e) {
      //关闭
      console.log(
        `WebSocket connection closed：${e.code} ${e.reason} ${e.wasClean}`
      )
      //重连
      // this.reconnect()
    },
    /**
     * 接收服务器推送的信息
     * @param msgEvent
     */
    onMessage(msgEvent) {
      //收到服务器信息，心跳重置并发送
      this.startHeartbeat()
      const webData = msgEvent.data
      if (webData.indexOf('pong') > 0) {
        return
      } else {
        let text = JSON.parse(webData)
        console.log('消息通信', text)
        if (text?.msgType == 'warn') {
          this.$nextTick(() => {
            // 打开弹框
            this.show = true
            // 赋值数据
            this.alarmData = text
            // 播放音频
            if (this.play) {
              this.play = false
              document.getElementById('audio').pause()
            } else {
              this.play = true
            }
            // 开启声音
            let music1 = new Audio()
            if (text.warnSubCategory == 'ELECTRONIC_FENCE_ALARM') {
              // 电子围栏
              music1 = require('./static/dianziweilan.mp3')
            } else if (text.warnSubCategory == 'INFRARED_ALARM') {
              // 红外探测
              music1 = require('./static/hongwaitance.mp3')
            } else if (
              text.warnSubCategory == 'SOS' &&
              text.deviceType == 'X5'
            ) {
              // 手环紧急呼叫
              music1 = require('./static/shouhuanjinjihujiao.mp3')
            } else if (text.warnSubCategory == 'SOS') {
              // 紧急呼叫
              music1 = require('./static/jinjihujiao.mp3')
            } else if (text.warnSubCategory == 'FALL_ALARM') {
              // 老人跌倒
              music1 = require('./static/laorendiedao.mp3')
            } else if (text.warnSubCategory == 'ABNORMAL_BREATHING_ALARM') {
              // 老人呼吸异常
              music1 = require('./static/laorenhuxiyichang.mp3')
            } else if (text.warnSubCategory == 'LEAVING_BED_TOO_LONG_ALARM') {
              // 老人离床过长
              music1 = require('./static/laorenlichuangguochang.mp3')
            } else if (text.warnSubCategory == 'ABNORMAL_HEART_RATE_ALARM') {
              // 老人心率异常
              music1 = require('./static/laorenxinlvyichang.mp3')
            } else if (text.warnSubCategory == 'DOOR_ALARM') {
              // 门磁报警
              music1 = require('./static/mencibaojing.mp3')
            } else if (text.warnSubCategory == 'GAS_ALARM') {
              // 燃气泄漏报警
              music1 = require('./static/ranqixieloubaojing.mp3')
            } else if (text.warnSubCategory == 'FLOODED_ALARM') {
              // 水浸报警
              music1 = require('./static/shuiqingbaojing.mp3')
            } else if (text.warnSubCategory == 'SMOKE_ALARM') {
              // 烟感报警
              music1 = require('./static/yanganbaojing.mp3')
            } else {
              // 尖锐报警
              music1 = require('./static/yj.wav')
            }
            document.getElementById('audio').src = music1
            document.getElementById('audio').play()
            //   document.getElementById('audio').loop = 'loop'
          })
        }
      }
    },
    /**
     * 数据发送
     * @param msg
     */
    send(msg) {
      //数据发送
      this.webSocket.send(msg)
    },
    // 关闭弹框的回调
    closeFun() {
      this.show = false
      this.play = false
      document.getElementById('audio').pause()
    },
    // 去查看
    tohandle() {
      this.play = false
      document.getElementById('audio').pause()
      if (this.$route.path == '/other/warningCenter') {
        window.punctuationData()
        window.totalStatistics()
        window.getElderlyEquipment()
        window.workOrderListData()
      } else {
        this.$router.push({
          path: '/other/warningCenter',
        })
      }
      this.show = false
    },
  },
}
</script>

<style lang="scss">
@import './style/index.scss';
</style>
