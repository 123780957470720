<template>
  <!-- 报警弹框 -->
  <div class="alarmBox">
    <div id="smegma" v-if="show">
      <div class="sub"></div>
    </div>
    <div class="main-cs" v-if="show">
      <div class="smegma-main">
        <div class="smegma-box" v-if="alarmData">
          <div class="warning-info-name">
            <div>预警信息</div>
            <div>{{ alarmData.institutionName }}</div>
          </div>
          <div class="device-info">
            <div class="device-img">
              <img :src="alarmData.logo" alt="" />
            </div>
            <div class="device-police">
              {{ alarmData.warnCategoryMark }}：{{ alarmData.warnContent }}
            </div>
            <div class="device-time">{{ alarmData.warnTime }}</div>
            <div class="device-name">
              <span
                >{{ alarmData.deviceName }}({{ alarmData.supplerName }}/{{
                  alarmData.deviceType
                }})</span
              >
            </div>
          </div>
          <div class="elderlyInfo">
            <div class="elderlyInfo-main">
              <div v-if="alarmData.bindType == 1">
                <div
                  class="elderlyInfo-img"
                  v-if="alarmData.gender == 'FEMALE'"
                >
                  <img src="./static/nv.png" alt="" />
                </div>
                <div class="elderlyInfo-img" v-else>
                  <img src="./static/nan.png" alt="" />
                </div>
              </div>
              <div v-else>
                <div class="elderlyInfo-img">
                  <img src="./static/chuangwei.png" alt="" />
                </div>
              </div>
              <div class="elderlyInfo-item">
                <div class="elderlyInfo-name">{{ alarmData.targetName }}</div>
                <div class="elderlyInfo-info">
                  <div class="elderlyInfo-age" v-if="alarmData.bindType == 1">
                    <img src="./static/shengri.png" alt="" />
                    <div>{{ alarmData.age }}岁</div>
                  </div>
                  <div class="elderlyInfo-address">
                    <img src="./static/ditu.png" alt="" />
                    <div>{{ alarmData.warnAddress }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-btn">
            <div class="close" @click="close">关 闭</div>
            <div class="tohandle" @click="tohandle">去查看</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    alarmData: {
      type: Object,
      default: () => {
        return null
      },
    },
  },
  mounted() {
    // 设置告警状态为激活
    if (this.show) {
      this.setAlertState(true)
    }
  },
  methods: {
    setAlertState(active) {
      var alertOverlay = document.getElementById('smegma')
      if (active) {
        alertOverlay.classList.add('alert-active')
      } else {
        alertOverlay.classList.remove('alert-active')
      }
    },
    close() {
      this.$emit('close')
    },
    tohandle() {
      this.$emit('tohandle', this.alarmData)
    },
  },
}
</script>
<style lang="scss" scoped>
@import './style/alarmBox.scss';
</style>
