import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'

Vue.config.productionTip = false

// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import moment from 'moment'
// lodash
import _ from 'lodash'
Vue.prototype._ = _

// vue-seamless-scroll
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.prototype.moment = moment
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
