import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 引入所有js模块
const context = require.context('./modules', false, /\.js$/)
const modules = context.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const fileModule = context(modulePath)
  modules[moduleName] = fileModule.default
  return modules
}, {})

export const constantRoutes = [
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
  },
  // 404页
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]

export const asyncRoutes = [
  // 门店一级页面
  modules.homePage,
  // 机构二级页面
  modules.institutionPage,
  // 政府二级
  modules.govPage,
  // 其他页面
  modules.otherPage,
]

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
    mode: 'history',
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
