import Layout from '@/layout'

// 门店一级
const homePage = {
  path: '/',
  component: Layout,
  redirect: '/home',
  name: 'first',
  children: [
    {
      path: 'home',
      component: () => import('@/views/homePage/index.vue'),
      name: 'firstLevelPage',
      meta: {
        title: '机构一级',
      },
    },
    {
      path: 'videopage',
      component: () => import('@/views/homePage/right/videopage/videopage.vue'),
      name: 'videopage',
      meta: {
        title: '监控数据',
      },
    },
  ],
}

export default homePage
