// vue路由
import router from './router'
import { getTokenFromCode, getTenantList } from '@/api/user'
// 引入element-ui中的message消息提示
import { Message } from 'element-ui'
import store from './store'

// 引入NProgress进度条插件
import _ from 'lodash'
import NProgress from 'nprogress'
// NProgress进度条样式
import 'nprogress/nprogress.css'
// 从cookie中获取令牌
import {
  getToken,
  setToken,
  removeToken,
  setTenantNo,
  removeTenantNo,
  removeUserInfo,
} from '@/utils/auth.js'
// \ n程序配置
NProgress.configure({ showSpinner: false })
// 白名单中的重定向
const whiteList = ['/login']
//vue router全局前置守卫
router.beforeEach(async (to, from, next) => {
  // 开始进度条
  NProgress.start()
  //确定用户是否已登录
  const hasToken = getToken()
  //判断是否有token
  if (hasToken) {
    console.log('to.path', to.path)
    // 如果已登录，请重定向到主页
    if (to.path === '/login') {
      // 进入到首页
      next()
      // 结束进度条
      NProgress.done()
    } else {
      try {
        let roles = store.state.user.roles
        console.log('有没有身份', roles)
        if (!roles) {
          // 获取到的用户信息
          const data = await store.dispatch('user/getInfo')
          // 设置路由
          const routerData = await store.dispatch('permission/getRouter', data)
          if (!routerData.list.includes('region_screen')) {
            //判断是否拥有大屏权限
            Message.error('该账号未有数字化大屏权限')
            removeToken()
            removeTenantNo()
            removeUserInfo()
            // 从新定向到登录页
            next(`/login`)
            NProgress.done()
          } else {
            if (routerData.routes && routerData.routes.length > 2) {
              router.addRoutes(routerData.routes)
              next({ ...to, replace: true })
            } else {
              next()
            }
            NProgress.done()
          }
        } else {
          next()
          NProgress.done()
        }
      } catch (error) {
        // 删除令牌并转到登录页面重新登录
        removeToken()
        removeTenantNo()
        removeUserInfo()
        // 消息提示错误
        Message.error(error || '异常情况')
        // 从新定向到登录页
        next(`/login?redirect=${to.path}`)
        // 结束
        NProgress.done()
      }
    }
  } else {
    /* 没有token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 没有访问权限的其他页面将重定向到登录页面。
      let query = window.location.search.substring(1)
      let vars = query.split('&')
      let code
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == 'code') {
          code = pair[1]
        }
      }
      if (code) {
        getTokenFromCode(code)
          .then((res) => {
            if (res.code == 0) {
              setToken(res.data.token_type + res.data.access_token)
              let tenantNo = res.data.account.tenantNo
              setTenantNo(tenantNo)
              getTenantList().then(async (res) => {
                if (res.code == 0) {
                  let areaList = []
                  areaList = res.data.map((item) => {
                    return {
                      name: item.name,
                      code: item.areaCode,
                      tenantNo: item.tenantNo,
                    }
                  })

                  areaList = areaList.filter((item) => {
                    return item.name
                  })
                  if (areaList.length == 0) {
                    this.$message.error('该账号未有区域端大屏')
                    removeToken()
                    removeTenantNo()
                    removeUserInfo()
                    // 从新定向到登录页
                    next(`/login`)
                    NProgress.done()
                    return
                  } else {
                    areaList.map((item) => {
                      if (item.tenantNo == tenantNo) {
                        sessionStorage.setItem(
                          'activeCode',
                          JSON.stringify(item.code)
                        )
                      }
                    })
                    sessionStorage.setItem(
                      'hasAreaList',
                      JSON.stringify(areaList)
                    )

                    // 获取到的用户信息
                    const data = await store.dispatch('user/getInfo')
                    // 设置路由
                    const routerData = await store.dispatch(
                      'permission/getRouter',
                      data
                    )
                    if (!routerData.list.includes('region_screen')) {
                      //判断是否拥有大屏权限
                      Message.error('该账号未有数字化大屏权限')
                      removeToken()
                      removeTenantNo()
                      removeUserInfo()
                      // 从新定向到登录页
                      next(`/login`)
                      NProgress.done()
                    } else {
                      if (routerData.routes && routerData.routes.length > 2) {
                        router.addRoutes(routerData.routes)
                        next({ ...to, replace: true })
                      } else {
                        next()
                      }
                      NProgress.done()
                    }
                  }
                } else {
                  next(`/login`)
                  NProgress.done()
                }
              })
            } else {
              next(`/login`)
              NProgress.done()
            }
          })
          .catch(() => {
            next(`/login`)
            NProgress.done()
          })
      } else {
        // 结束进度条
        next(`/login`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // 结束进度条
  NProgress.done()
})
